<template>
  <div class="auth-form-wrapper">
    <div class="auth-form-wrapper-header">
      <h1 class="card-title fw-bold mb-1 auth-main-title">
        {{ title }}
      </h1>
      <!-- Custom header text for specific communities -->
      <div 
        v-if="customHeaderText" 
        v-html="customHeaderText" 
        class="custom-header-text mb-2"
      >
      </div>
      <p class="card-text mb-2">
        {{ subtitle }}
      </p>
    </div>

    <b-alert
      v-if="error"
      variant="danger"
      show
      class="text-center w-100 rounded pb-2 pt-2"
    >
      {{ error }}
    </b-alert>

    <!-- Main content slot -->
    <slot></slot>

    <!-- Custom footer text for specific communities -->
    <div 
      v-if="customFooterText" 
      v-html="customFooterText" 
      class="custom-footer-text mt-2"
    >
    </div>
  
    <!-- Brand logo -->
    <div v-if="showNectiosBrand" class="text-center login-community mt-3">
      <b-link :href="landingURL" target="_blank">
        <b-img :src="brand" alt="Power by Nectios" class="brand" />
      </b-link>
    </div>

    <!-- Footer -->
    <footer-component 
      v-if="showFooter" 
      :community="community" 
    />
  </div>
</template>

<script>
import BrandLogo from '@/assets/images/placeholders/light/nectios-favicon.png';
import FooterComponent from '@/auth/components/FooterComponent.vue';

// Define constants for community specific values
const COMMUNITY_KEYS = {
  INHOSPITECNIA: 'f6d4dec0-e1ec-11ec-ac71-cd2eb883fdc3'
};

// Define constant values for custom text
const INHOSPITECNIA_VALUES = {
  SIGN_IN_TITLE: `<p class="card-text mb-2">in·Hospitecnia es la comunidad de usuarios de Hospitecnia. ¡Un nuevo espacio de interacción! <br><br>Introduce tus <b>credenciales de usuario de Hospitecnia</b> para acceder a la comunidad de Hospitecnia. <br><br>Si todavía no eres usuario de Hospitecnia puedes darte de alta de forma gratuita <a href="https://hospitecnia.com/signup/" target="_blank">en la página de Hospitecnia</a>.<br><br>Si no recuerdas tu contraseña la puedes reiniciar <a href="https://hospitecnia.com/user/password/" target="_blank">aquí</a>.<br><br>Si tienes cualquier problema con el acceso escríbenos a <a href="mailto:info@hospitecnia.com" target="_blank">info@hospitecnia.com</a>.</p>`,
  SIGN_IN_FOOTER: `<p class="mt-1 text-center">Al registrarte aceptas los<a href="https://app.nectios.com/inhospitecnia/legal-center/terms_conditions?locale=es" target="_blank"> términos y condiciones </a>y las<a href="https://app.nectios.com/inhospitecnia/legal-center/privacy_policy?locale=es" target="_blank"> políticas de privacidad </a> de inhospitecnia</p>`
};

export default {
  name: 'AuthFormWrapper',
  
  components: {
    FooterComponent
  },

  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    error: {
      type: String,
      default: null
    },
    community: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      customHeaderText: null,
      customFooterText: null
    }
  },
  computed: {
    showNectiosBrand() {
      return this.community && this.community.level > 1;
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL_COMMUNITY
    },
    communityKey() {
      return this.community?.key || null;
    },
    showFooter() {
      return this.community && (this.community.slug === 'nectios' && this.community.level > 1);
    },
    isInhospitecnia() {
      const result = this.communityKey === COMMUNITY_KEYS.INHOSPITECNIA;
      // console.log('isInhospitecnia computed property called, communityKey:', this.communityKey, 'INHOSPITECNIA key:', COMMUNITY_KEYS.INHOSPITECNIA, 'result:', result);
      return result;
    },
    brand() {
      return BrandLogo
    },
  },
  created () {
    this.setCustomHeaderText()
    this.setCustomFooterText()
    // console.log('AuthFormWrapper created with community:', this.community)
  },
  watch: {
    community: {
      immediate: true,
      deep: true,
      handler(newVal) {
        // console.log('AuthFormWrapper community changed:', newVal)
        if (newVal) {
          this.setCustomHeaderText()
          this.setCustomFooterText()
        }
      }
    }
  },
  methods: {
    setCustomHeaderText() {
      // console.log('Setting custom header text, isInhospitecnia:', this.isInhospitecnia, 'communityKey:', this.communityKey);
      if (this.isInhospitecnia) {
        this.customHeaderText = INHOSPITECNIA_VALUES.SIGN_IN_TITLE;
      } else {
        this.customHeaderText = null;
      }
    },
    setCustomFooterText() {
      // console.log('Setting custom footer text, isInhospitecnia:', this.isInhospitecnia, 'communityKey:', this.communityKey);
      if (this.isInhospitecnia) {
        this.customFooterText = INHOSPITECNIA_VALUES.SIGN_IN_FOOTER;
      } else {
        this.customFooterText = null;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // padding: 2rem;

  .card-title {
    text-align: left;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }

  .card-text {
    text-align: left;
    margin-bottom: 0.5rem;
  }
}

.auth-form-wrapper-header {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
}

.auth-form-wrapper-content {
  width: 100%;
}

// Form styles
.auth-form {
  width: 100%;
  margin-top: 0.5rem;

  .mb-1 {
    margin-bottom: 1rem;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.571rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #6e6b7b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #6e6b7b;
    background-color: #fff;
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  }
}

.input-group-merge {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;

  .form-control {
    &:not(:last-child) {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.form-control-merge {
      border-right: 0;
      padding-right: 0;
    }
  }

  .input-group-append {
    display: flex;
    margin-left: -1px;

    .input-group-text {
      display: flex;
      align-items: center;
      padding: 0.571rem 1rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25;
      color: #6e6b7b;
      text-align: center;
      white-space: nowrap;
      background-color: #f8f8f8;
      border: 1px solid #d8d6de;
      border-left: 0;
      border-top-right-radius: 0.357rem;
      border-bottom-right-radius: 0.357rem;
      height: 100%;

      &:hover {
        background-color: #efefef;
      }
    }
  }
}

// Button styles
.auth-btn-container {
  display: flex;
  justify-content: center;
}

.auth-btn {
  &.auth-btn-primary {
    margin: 0.5rem 0;
    padding: 0.786rem 1.5rem;
    border-radius: 0.358rem;
  }
}

// Utility classes
.cursor-pointer {
  cursor: pointer;
}

.auth-link {
  color: var(--primary-color);
  text-decoration: none;
  margin-left: 0.5rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 991.98px) {
  .auth-wrapper {
    padding: 1.5rem;
  }
}

@media (max-width: 575.98px) {
  .auth-wrapper {
    padding: 1rem;
  }
}
  .brand {
    height: 32px;
  }
  // .custom-header-text {
  //   font-size: 0.9rem;
  //   color: #6e6b7b;
    
  //   a {
  //     color: var(--primary-color);
  //     text-decoration: none;
      
  //     &:hover {
  //       text-decoration: underline;
  //     }
  //   }
  // }
</style> 