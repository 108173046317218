<template>
  <div class="footer-component mt-4">
    <div class="text-center">
      <div v-if="community.logo" class="community-logo mb-2">
        <b-img :src="community.logo" :alt="community.name" height="40" />
      </div>
      <div v-if="community.name" class="community-name mb-1">
        {{ translate(community.name) }}
      </div>
      <div v-if="community.description" class="community-description small text-muted">
        {{ translate(community.description) }}
      </div>
      <div v-if="community.website" class="community-website mt-2">
        <b-link :href="community.website" target="_blank" class="btn btn-sm btn-outline-secondary">
          {{ $t('auth.footer.visit-website') }}
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils'

export default {
  name: 'FooterComponent',
  
  props: {
    community: {
      type: Object,
      required: true
    }
  },
  
  methods: {
    translate(field) {
      return translateTranslationTable(this.$i18n.locale, field)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-component {
  padding-top: 1.5rem;
  border-top: 1px solid #ebe9f1;
  
  .community-logo {
    img {
      max-height: 40px;
      max-width: 100%;
    }
  }
  
  .community-name {
    font-weight: 600;
    font-size: 1rem;
  }
  
  .community-description {
    max-width: 80%;
    margin: 0 auto;
  }
}
</style> 